
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_comment_reports(params)  {
            return await axios.post('comment_reports/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_comment_reports()  {
            // return await axios.get('comment_reports/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_comment_reports')
        },
        async delete_comment_reports(comment_report_id)  {
            return await axios.post('comment_reports/delete.php' , {
                comment_report_id : comment_report_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(comment_report_id)  {
            return await axios.post('comment_reports/readOne.php' , {
                comment_report_id : comment_report_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_comment_reports(params)  {
            return await axios.post('comment_reports/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_comment_reports(params)  {
            return await axios.post('comment_reports/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
